/* eslint-disable max-len, object-curly-newline */

const GAEvents = {
  COMMUTE_ADDRESS: {
    SET:                              { eventCategory: 'commute-address',             eventAction: 'set'       },
  },

  COST_ESTIMATE_JUMP_LINK: {
    CLICK:                            { eventCategory: 'cost-estimate-jump-link',     eventAction: 'click'     },
  },

  EMAIL_PROPERTY_ALERTS: {
    CLICK:                            { eventCategory: 'email-property-alerts',       eventAction: 'click'     },
  },

  HOME_ATTRIBUTES: {
    EXPAND:                           { eventCategory: 'home-attributes',             eventAction: 'expand'    },
    CLICK: {
      SEE_AGENT_INFO:                 { eventCategory: 'home-attributes',             eventAction: 'click', eventLabel: 'see-agent-info' },
    },
  },

  LISTING_DESCRIPTION: {
    READ_MORE:                        { eventCategory: 'listing-description',         eventAction: 'read-more' },
  },

  LISTING_IMAGE_GALLERY: {
    OPEN:                             { eventCategory: 'listing-image-gallery',       eventAction: 'open'      },
  },

  LISTING_MAP: {
    OPEN: {
      VIA_ABOVE_FOLD_LINK:            { eventCategory: 'listing-map',                 eventAction: 'open', eventLabel: 'via-above-fold-link' },
      VIA_MAP_PREVIEW:                { eventCategory: 'listing-map',                 eventAction: 'open', eventLabel: 'via-map-preview'     },
    },
    VIEW_CHANGE:                      { eventCategory: 'listing-map',                 eventAction: 'view-change' /* eventLabel: streetview|roadmap|hybrid */ },
  },

  LISTING_NAVIGATION: {               // `transport: 'beacon'` Required for tracking link clicks
    CLICK:                            { eventCategory: 'listing-navigation',          eventAction: 'click', /* eventLabel: [src], */ transport: 'beacon' },
  },

  MOBILE_SEARCH_VIEW_MODE: {
    CHANGE: {                         // `transport: 'beacon'` Required for tracking link clicks
      TO_LIST:                        { eventCategory: 'mobile-search-view-mode',     eventAction: 'change', eventLabel: 'to-list-view', transport: 'beacon' },
      TO_MAP:                         { eventCategory: 'mobile-search-view-mode',     eventAction: 'change', eventLabel: 'to-map-view',  transport: 'beacon' },
    },
  },

  MORTGAGE_CALCULATOR_MODAL: {
    OPEN:                             { eventCategory: 'mortgage-calculator-modal',   eventAction: 'open'      },
  },

  NATIVE_SHARE_SHEET: {
    OPEN:                             { eventCategory: 'native-share-sheet',          eventAction: 'open'      },
    SHARE:                            { eventCategory: 'native-share-sheet',          eventAction: 'share'     },
    CANCEL:                           { eventCategory: 'native-share-sheet',          eventAction: 'cancel'    },
  },

  NOTIFICATION_CENTER: {
    CLICK: {
      NOTIFICATION:                   { eventCategory: 'notification-center',         eventAction: 'click', eventLabel: 'notification' },
      SAVE_SEARCH:                    { eventCategory: 'notification-center',         eventAction: 'click', eventLabel: 'save-search'  },
    },
    OPEN:                             { eventCategory: 'notification-center',         eventAction: 'open'      },
  },

  PROPERTY: {
    SAVE:                             { eventCategory: 'property',                    eventAction: 'save'      },
    TRASH:                            { eventCategory: 'property',                    eventAction: 'trash'     },
    UNSAVE:                           { eventCategory: 'property',                    eventAction: 'unsave'    },
    UNTRASH:                          { eventCategory: 'property',                    eventAction: 'untrash'   },
  },

  SALE_HISTORY: {
    EXPAND:                           { eventCategory: 'sales-history',               eventAction: 'expand'    },
  },

  SAVE_SEARCH: {
    SUBMIT: {
      NO_REDIRECT:                    { eventCategory: 'save-search',                 eventAction: 'submit', eventLabel: 'no-redirect'        },
      REDIRECT_TO_SIGNUP:             { eventCategory: 'save-search',                 eventAction: 'submit', eventLabel: 'redirect-to-signup' },
    },
    SUCCESS:                          { eventCategory: 'save-search',                 eventAction: 'success'                                  },
    ERROR:                            { eventCategory: 'save-search',                 eventAction: 'error'                                    },
  },

  SCHOOLS: {
    EXPAND:                           { eventCategory: 'schools',                     eventAction: 'expand'    },
  },

  SEARCH: {
    SUBMIT: {
      VIA_FILTERS_MODAL:              { eventCategory: 'search',                      eventAction: 'submit', eventLabel: 'via-filters-modal'     },
      VIA_HOMEPAGE_HERO:              { eventCategory: 'search',                      eventAction: 'submit', eventLabel: 'via-homepage-hero'     },
      VIA_HEADER_SEARCH_BAR:          { eventCategory: 'search',                      eventAction: 'submit', eventLabel: 'via-header-search-bar' },
    },
  },

  SHOWING_REQUEST: {
    CHECKOUT_MODAL: {
      OPEN:                           { eventCategory: 'showing-request-checkout-modal', eventAction: 'open' /* eventLabel: via-[src] */ },
    },

    SUBMIT:                           { eventCategory: 'showing-request',             eventAction: 'submit'  /* eventLabel: via-[src] */ },
  },

  CASH_OFFER: {
    CHECKOUT_MODAL: {
      OPEN:                           { eventCategory: 'cash-offer-request-checkout-modal', eventAction: 'open' /* eventLabel: via-[src] */ },
    },

    SUBMIT:                           { eventCategory: 'cash-offer-request',             eventAction: 'submit'  /* eventLabel: via-[src] */ },
  },

  LISTING_PROVIDER_CONTACT_LINK: {
    CLICK:                            { eventCategory: 'listing-provider-contact-link', eventAction: 'click' },
  },

  YOU_SEEM_INTERESTED_POPUP: {
    DISPLAY:                          { eventCategory: 'you-seem-interested-modal',   eventAction: 'display'   },
    SUBMIT:                           { eventCategory: 'you-seem-interested-modal',   eventAction: 'submit'    },
  },

  CONNECT_WITH_AN_AGENT: {
    SUBMIT:                           { eventCategory: 'connect-with-an-agent', eventAction: 'submit' },
  },

  TEXT_CTA_BUTTON: {
    CLICK:                            { eventCategory: 'text-cta', eventAction: 'click' },
  },
}

export default GAEvents
