import IdleScheduler from 'lib/IdleScheduler'

const IDLE_TIMEOUT = 2000
const IdleAnalytics = new IdleScheduler({ timeout: IDLE_TIMEOUT })

export default function ga(...args) {
  if (SERVER_SIDE) throw new Error('Google Analytics can’t be called from the server!').stack

  // Send beacon events immediately (need to fire before the page unloads)
  if (args[0] === 'send' && args[1].transport === 'beacon') {
    window.ga(...args)
  } else {
    IdleAnalytics.schedule(() => window.ga(...args))
  }
}
