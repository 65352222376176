import memoize from 'lib/memoize'
import ComponentLoader from 'legacy/lib/ComponentLoader'

import ga from './ga'

export default class GAReporter extends ComponentLoader {
  static initialize() {
    if (SERVER_SIDE) return

    const analytics = window.estately.analytics

    ga('create', analytics.id, 'auto')

    ga('set', {
      // Content Grouping
      // See https://support.google.com/analytics/answer/2853423
      contentGroup1: analytics.cg1,

      // --------------------------------------------------------
      // Custom Dimensions
      //
      // See Analytics > Admin > Custom Definitions for current settings
      // https://analytics.google.com/analytics/web/#/a1073029w1864254p1881094/admin/custom-dimensions/

      // 1: MLS
      dimension1: analytics.d1,

      // 2: Property Status
      dimension2: analytics.d2,

      // 3: Active A/B Test
      // NOTE: Has to be reset in AJAX view partials that enroll AB tests - See #15363
      dimension3: analytics.d3,

      // 4: User Role
      // Used to slice by role and filter out Estately employees
      dimension4: this.userRole,

      // 8: User Agent
      // Used to filter out testing tools like PageSpeed Insights
      dimension8: window.navigator.userAgent,

      // --------------------------------------------------------

      // Google Optimize - A/B testing Google Analytics Events
      //
      // See https://developers.google.com/optimize/devguides/experiments#add-ga-tracking-code-to-variations
      exp: analytics.exp,

      // Include search params in page tracking
      page: window.location.pathname + window.location.search,
    })

    ga('send', 'pageview')

    // Server-side events
    // See GoogleAnalytics::EVENTS for possible server-side events
    analytics.events.forEach(event => this.trackEvent(event))
    analytics.gtm_data_layer.forEach(event => this.pushEventToGTM(event))
  }

  // Sends events to Universal analytics
  static trackEvent(event, overrides) {
    const payload = { ...event, ...overrides, hitType: 'event' }

    ga('send', payload)
  }

  // Pushes events to google tag manager
  static pushEventToGTM(dataLayer) {
    if ('event' in dataLayer) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(dataLayer)
    }
  }

  /* User Role
   *
   * User roles are ranked from 'anonymous' up to 'employee'. As a user logs in and
   * changes roles, we keep whatever is the highest role and report that for all future
   * visits. So once an agent or an Estately employee logs in, we keep reporting that
   * role even if they log out or spoof other users
   *
   */
  @memoize
  static get userRole() {
    let [rank, role] = JSON.parse(localStorage.getItem('analytics:user-role') || '[-1]')

    if (window.estately.analytics.rank > rank) {
      rank = window.estately.analytics.rank
      role = window.estately.analytics.role

      localStorage.setItem('analytics:user-role', JSON.stringify([rank, role]))
    }

    return role
  }
}
