export default class IdleScheduler {
  constructor({ timeout = null } = {}) {
    this.timeout      = timeout
    this.pendingTasks = []
    this.isScheduled  = false
  }

  schedule(task) {
    if (typeof task === 'function') this.pendingTasks.unshift(task)

    if (this.isScheduled) return

    this.isScheduled = true

    requestIdleCallback(this.process, { timeout: this.timeout })
  }

  process = (deadline) => {
    this.isScheduled = false

    while ((deadline.timeRemaining() > 0 || deadline.didTimeout) && this.pendingTasks.length) {
      const performTask = this.pendingTasks.pop()
      performTask()
    }

    if (this.pendingTasks.length) this.schedule()
  }
}
